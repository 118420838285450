import {ChangeDetectorRef, Component, OnInit, ViewEncapsulation} from '@angular/core';

import {SubscribersService} from 'src/app/core/services/subscribers.service';
import {ThemeOptions} from 'src/app/core/theme-options';
import {UserModel} from "@coremodels";
import {AuthService, CommunicationService} from "@coreservices";
import {Router} from '@angular/router';
import {CommonConstants as Constants} from 'src/app/core/constants/common-constants';
import {DecorNoteComponent} from './modal/decor-note/decor-note.component';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css'],
  // encapsulation: ViewEncapsulation.None
})
export class FooterComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  isUserAuthenticated: boolean = false;
  public userData: UserModel = new UserModel();
  isTradeUser: boolean = false;
  constants = Constants;
  public screenWidth: any;
  public screenHeight: any;

  constructor(
    private globals: ThemeOptions,
    private subscriberService: SubscribersService,
    private changeDetectionRef: ChangeDetectorRef,
    private authService: AuthService,
    private router: Router,
    private modalService: NgbModal,
    private communicationService: CommunicationService,
  ) {
    this.subscriberService.isUserLoggedIn.subscribe((options) => {
      this.globals.isUserAuthenticated = options;
      this.isUserAuthenticated = options;
      if (this.isUserAuthenticated) {
        this.userData = this.authService.getUserProfile();
        this.isTradeUser = this.userData.role === Constants.USER_TYPE_TRADE;
      } else {
        this.isTradeUser = false;
      }
      this.changeDetectionRef.markForCheck();
    });
  }

  ngOnInit(): void {
    this.screenWidth = window.innerWidth;
    this.screenHeight = window.innerHeight;

    this.userData = this.authService.getUserProfile();
    this.isUserAuthenticated = this.authService.isUserAuthenticated();
    if (this.userData && this.isUserAuthenticated) {
      this.isTradeUser = this.userData.role === Constants.USER_TYPE_TRADE;
    }
  }

  rightSidebarToggle(formType: string) {
    this.globals.rightSidebar = true;
    this.globals.registrationForm = formType;
    setTimeout(() => {
      this.subscriberService.rightDrawerToggle(this.globals.rightSidebar);
      this.subscriberService.registrationFormToggle(this.globals.registrationForm);
    }, 200);
    this.communicationService.emitRightSidebarClick(true);
  }

  myaccountAction() {
    if (!this.isUserAuthenticated) {
      this.globals.rightSidebar = true;
      this.subscriberService.rightDrawerToggle(this.globals.rightSidebar);
    } else {
      // Code for redirection
    }
  }

  redirectTo(path: string) {
    this.router.navigate(['/' + path]);
  }

  onDecorNoteOpen() {
    const modalRef = this.modalService.open(DecorNoteComponent, {
      centered: true,
      windowClass: 'decorNote',
    });
  }

  getBackgroundImageUrl(): string {
    return `url('https://ik.imagekit.io/qoo0wczig/WEBSITE_ASSET_STAGING/sign-up-banner.png?tr=w-${this.screenWidth}')`;
  }

}
